<template>
  <modal
    :exibir="exibir"
    @fechar="fecharModal"
    :titulo="titulo"
    :campos="$refs"
    id="ModalEndereco"
  >
    <div class="container">
      <b-row class="flex-column flex-sm-row w-100">
        <input-mask
          v-model="formEndereco.cep"
          :label="$t('PESSOAS.CEP')"
          type="text"
          :mask="['#####-###']"
          placeholder="Digite apenas números"
          class="col-md-6"
        />

        <input-select-search
          ref="paisId"
          v-model="formEndereco.paisId"
          :label="$t(`PESSOAS.PAIS`)"
          :options="gets.listaPais"
          :isDisabled="disabled.pais"
          class="col-md-6"
        />

        <input-select-search
          ref="estadoId"
          v-model="formEndereco.estadoId"
          :label="$t(`PESSOAS.ESTADO`)"
          :options="gets.listaEstado"
          placeholder="Digite ou selecione"
          class="col-md-6"
          required
        />

        <input-select-search
          ref="municipioId"
          v-model="formEndereco.municipioId"
          :label="$t(`PESSOAS.MUNICIPIO`)"
          :options="gets.listaMunicipio"
          placeholder="Digite ou selecione"
          class="col-md-6"
          required
        />

        <input-text
          ref="bairro"
          v-model="formEndereco.bairro"
          :label="$t('PESSOAS.BAIRRO')"
          placeholder="Digite aqui"
          class="col-md-6"
          required
        />

        <input-text
          ref="logradouro"
          v-model="formEndereco.logradouro"
          :label="$t('PESSOAS.LOGRADOURO')"
          placeholder="Digite aqui"
          class="col-md-6"
          required
        />

        <input-text
          ref="complemento"
          v-model="formEndereco.complemento"
          :label="$t('PESSOAS.COMPLEMENTO')"
          placeholder="Digite aqui"
          class="col-md-6"
        />

        <input-text
          ref="numero"
          v-model="formEndereco.numero"
          :label="$t('PESSOAS.NUMERO')"
          placeholder="Digite aqui"
          class="col-md-6"
          :maxLength="10"
          required
        />
      </b-row>
    </div>
    <template v-slot:modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">{{
          $t('GERAL.FECHAR')
        }}</b-button>
        <b-button variant="primary" @click="salvar">{{
          $t('GERAL.SALVAR')
        }}</b-button>
      </div>
    </template>
  </modal>
</template>

<script>
// Utils & Aux:
import axios from 'axios';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import EnderecoService from '@/common/services/endereco/endereco.service';
import InstituicaoEnsinoService from '@/common/services/instituicao-ensino/instituicao-ensino.js';

// Vuelidate
import { validationMixin } from 'vuelidate';

// Components:
import Modal from '@/components/modal/Modal.vue';
import { InputSelectSearch, InputText, InputMask } from '@/components/inputs';

export default {
  mixins: [validationMixin],
  components: {
    Modal,
    InputSelectSearch,
    InputText,
    InputMask,
  },
  props: {
    id: { type: String, default: null },
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
    endereco: { type: Object, default: Object },
  },
  data() {
    return {
      formEndereco: [],
      dadosPesquisaCEP: [],
      gets: {
        brasil: [],
        listaPais: [],
        listaEstado: [],
        listaMunicipio: [],
      },
      disabled: {
        pais: true,
      },
    };
  },
  mounted() {
    this.getPaises();
  },
  computed: {
    titulo() {
      return !this.endereco.id
        ? this.$t('GERAL.CADASTRAR')
        : this.$t('GERAL.EDITAR');
    },
  },
  watch: {
    'formEndereco.estadoId': 'getMunicipios',
    'formEndereco.cep': 'buscaDadosCEP',
    exibir: 'verificaNovoOuEditar',
  },
  methods: {
    // FUNÇÕES PARA OBTENÇÃO DE DADOS:
    getPaises() {
      this.$store.dispatch(START_LOADING);
      EnderecoService.buscarNacionalidades()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.nome,
          }));
          this.gets.listaPais = data;
          this.getBrasil(); // atualmente fazemos busca de estado BR então salvo o BR direto no this.gets.brasil
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getBrasil() {
      this.gets.listaPais.forEach((element) => {
        if (element.nome == 'Brasil') {
          this.formEndereco.paisId = element.id;
          return (this.gets.brasil = element);
        }
      });
    },
    getEstados() {
      this.$store.dispatch(START_LOADING);
      EnderecoService.buscarEstados(this.gets.brasil.id)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.nome,
          }));

          this.gets.listaEstado = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getMunicipios() {
      this.$store.dispatch(START_LOADING);
      if (this.formEndereco.estadoId)
        EnderecoService.buscarMunicipios(this.formEndereco.estadoId)
          .then(({ data }) => {
            data = data.map((row) => ({
              ...row,
              value: row.id,
              text: row.nome,
            }));
            this.gets.listaMunicipio = data;
            if (this.dadosPesquisaCEP) {
              this.encontrarMunicipio();
            }
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
    },
    encontrarMunicipio() {
      this.gets.listaMunicipio.forEach((element) => {
        if (element.nome == this.dadosPesquisaCEP.localidade) {
          this.formEndereco.municipioId = element.id;
        }
      });
    },
    buscaDadosCEP() {
      if (this.formEndereco.cep && this.formEndereco.cep.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.formEndereco.cep}/json/`)
          .then((response) => {
            if (response.data) {
              if (response.data.erro) {
                this.formEndereco = {};
                this.formEndereco.paisId = this.gets.brasil.id;
                this.formEndereco.cep = '';
                mensagem.erro(this.$t('CAMPOS_VALIDACAO.CEP_INEXISTENTE'));
                return;
              }

              this.dadosPesquisaCEP = response.data;
              if (this.dadosPesquisaCEP) {
                this.preenchimentoAutomatico();
              }
            }
          })
          .catch((error) => {
            this.formEndereco = {};
            this.formEndereco.paisId = this.gets.brasil.id;
            this.formEndereco.cep = '';
            mensagem.erro(this.$t('CAMPOS_VALIDACAO.CEP_INEXISTENTE'), error);
          });
      }
    },
    preenchimentoAutomatico() {
      this.gets.listaEstado.forEach((element) => {
        if (element.sigla == this.dadosPesquisaCEP.uf) {
          this.estadoId = element.id;
          this.formEndereco.estadoId = element.id;
          // se encontrar o estado, está apto para munícipio:
          if (this.formEndereco.estadoId) {
            this.getMunicipios();
          }
        }
      });
      this.formEndereco.bairro = this.dadosPesquisaCEP.bairro;
      this.formEndereco.logradouro = this.dadosPesquisaCEP.logradouro;
    },
    criarDescricaoParaEstadoCidade(item, tipo) {
      const result = item.filter(
        ({ id }) => id === this.formEndereco[`${tipo}Id`]
      );
      this.formEndereco[`${tipo}`] = result[0].nome;
    },
    // FUNÇÕES CRUD:
    salvar() {
      if (!this.validarFormulario()) return;

      // trata o CEP para o back:
      this.formEndereco.cep = this.formEndereco.cep.replace(/[^0-9]+/g, '');

      // se for uma instituição nova, só retorna para o form geral os endereços
      if (!this.id) {
        this.fecharModal();
        return this.form.enderecos.push(this.formEndereco);
      }

      // se for adição ou edição, adiciona o ID da instituição cadastrada e redireciona p/ função desejada:
      this.formEndereco.entidadeId = this.form.id;
      !this.formEndereco.id
        ? this.criar(this.formEndereco)
        : this.editar(this.formEndereco);
      this.fecharModal();
    },
    criar(novoEndereco) {
      this.$store.dispatch(START_LOADING);
      InstituicaoEnsinoService.criarEndereco(novoEndereco)
        .then(({ data }) => {
          mensagem.sucesso('Endereço criado!');
          this.form.enderecos = [];
          this.form.enderecos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar(editarEndereco) {
      this.$store.dispatch(START_LOADING);
      InstituicaoEnsinoService.editarEndereco(editarEndereco)
        .then(({ data }) => {
          mensagem.sucesso('Endereço atualizado!');
          this.form.enderecos = [];
          this.form.enderecos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES AUXILIARES:
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    verificaNovoOuEditar() {
      !this.id
        ? (this.formEndereco = this.endereco)
        : this.endereco.id
        ? (this.formEndereco = this.endereco)
        : (this.formEndereco = {});

      this.getPaises();
      this.getEstados();
      this.getMunicipios();
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
