<template>
  <b-table
    head-variant="light"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    :items="form.contatos"
    :fields="campos"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  >
    <template v-slot:cell(acoes)="item">
      <div class="flex">
        <b-dropdown variant="outline-default">
          <b-dropdown-item @click="confirmarExclusao(item)">
            {{ $t('GERAL.EXCLUIR') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
  </b-table>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import InstituicaoEnsinoService from '@/common/services/instituicao-ensino/instituicao-ensino.js';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  data() {
    return {
      id: this.$route.params.id,
      campos: [
        {
          key: 'nome',
          label: this.$t('PESSOAS.NOME'),
        },
        {
          key: 'tipoContatoDescricao',
          label: this.$t('PESSOAS.TIPO_CONTATO'),
        },
        {
          key: 'descricao',
          label: 'Contato',
        },

        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
    };
  },
  methods: {
    async excluir(id, contatoRow) {
      this.$store.dispatch(START_LOADING);
      InstituicaoEnsinoService.excluirContato(id)
        .then(() => {
          mensagem.sucesso('Contato removido!');
          this.form.contatos.splice(contatoRow.index, 1);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async confirmarExclusao(contatoRow) {
      const respostaExclusao = await mensagem.confirmacao(
        null,
        'Você realmente quer excluir este contato?'
      );
      if (respostaExclusao.isConfirmed) {
        if (!this.id) {
          this.form.contatos.splice(contatoRow.index, 1);
          return;
        }
        this.excluir(contatoRow.item.id, contatoRow);
      }
    },
  },
};
</script>
