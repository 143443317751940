<template>
  <topbar
    :titulo="titulo"
    :subtitulo="$t('ACADEMICO.FORMULARIO_INSTITUICAO_ENSINO')"
    :breadcrumbs="breadcrumbs"
  >
    <template #header>
      <div class="d-flex justify-content-between ml-md-4 gap-1">
        <div></div>

        <b-button variant="secondary" @click="retornar()">
          {{ $t('GERAL.VOLTAR') }}
        </b-button>
      </div>
    </template>
  </topbar>
</template>
<script>
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  props: {
    titulo: { type: String, default: '' },
  },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO.CURRICULO_ESCOLAR') },
        { titulo: this.$t('MENU.INSTITUICAO_ENSINO') },
        { titulo: this.titulo },
      ],
    };
  },
  methods: {
    retornar() {
      this.$router.push({ name: 'instituicao-ensino' });
    },
  },
};
</script>
