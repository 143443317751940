<template>
  <div>
    <titulo-formulario-instituicao-ensino :titulo="titulo" />
    <!-- Dados da Instituição -->
    <div class="mt-3">
      <h5>
        {{ $t('INSTITUICAO_ENSINO.DADOS_INSTITUICAO_ENSINO') }}
      </h5>
      <b-row class="flex-column flex-sm-row">
        <b-col cols="auto" class="col-sm-4">
          <input-text ref="nome" v-model="form.nome" label="Nome:" required />
        </b-col>
        <b-col cols="auto" class="col-sm-4">
          <input-text
            ref="razaoSocial"
            v-model="form.razaoSocial"
            label="Razão social:"
            required
          />
        </b-col>
        <b-col cols="auto" class="col-sm-4">
          <input-mask
            ref="cnpj"
            v-model="form.cnpj"
            label="CNPJ"
            type="text"
            :mask="['##.###.###/####-##']"
            placeholder="Digite apenas números"
            :minLength="18"
            required
          />
        </b-col>
      </b-row>
    </div>

    <!-- Contatos -->
    <div>
      <div class="d-flex justify-content-between my-3">
        <h5>
          {{ $t('INSTITUICAO_ENSINO.CONTATO_INSTITUICAO') }}
        </h5>
        <b-button variant="primary" @click="openModal('contato')">{{
          $t('PESSOAS.NOVO_CONTATO')
        }}</b-button>
      </div>
      <tabela-contato :form="form" @novo-contato="openModal" />
    </div>

    <!-- Endereços -->
    <div>
      <div class="d-flex justify-content-between my-3">
        <h5>
          {{ $t('INSTITUICAO_ENSINO.ENDERECO_INSTITUICAO') }}
        </h5>

        <b-button variant="primary" @click="openModal('endereco')">{{
          $t('PESSOAS.NOVO_ENDERECO')
        }}</b-button>
      </div>
      <tabela-endereco :form="form" @modal-endereco="openModal" />
    </div>

    <b-row>
      <b-col cols="12" class="text-right">
        <b-button variant="primary" @click="salvar">{{
          $t('GERAL.SALVAR')
        }}</b-button>
      </b-col>
    </b-row>

    <modal-endereco
      :id="id"
      :exibir="modais.endereco"
      :form="form"
      :endereco="modais.enderecoDados"
      @fechar="closeModal('endereco')"
    />

    <modal-contato
      :id="id"
      :exibir="modais.contato"
      :form="form"
      :contato="modais.contatoDados"
      @fechar="closeModal('contato')"
    />
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import rotas from '@/common/utils/rotas';

// Services:
import InstituicaoEnsinoService from '@/common/services/instituicao-ensino/instituicao-ensino.js';

// Models
import { Contato } from '@/common/models';
import { Endereco } from '@/common/models';
import { InstituicaoEnsino } from '@/common/models';

// Components:

import TabelaContato from './components/contato/Tabela';
import TabelaEndereco from './components/endereco/Tabela';
import { InputText, InputMask } from '@/components/inputs';
import ModalContato from './components/contato/Modal';
import ModalEndereco from './components/endereco/Modal';
import TituloFormularioInstituicaoEnsino from '@/views/academico/outras-instituicoes/instituicao-ensino/components/TituloFormularioInstituicaoEnsino';

export default {
  components: {
    TituloFormularioInstituicaoEnsino,
    InputText,
    InputMask,
    TabelaContato,
    TabelaEndereco,
    ModalContato,
    ModalEndereco,
  },
  data() {
    return {
      id: this.$route.params.id,
      form: new InstituicaoEnsino({}),
      modais: {
        contato: false,
        contatoDados: {},
        enderecoDados: {},
        endereco: false,
      },
    };
  },
  mounted() {
    this.id ? this.getInstituicao() : {};
  },
  computed: {
    titulo() {
      return !this.id ? this.$t('GERAL.CADASTRAR') : this.$t('GERAL.EDITAR');
    },
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getInstituicao() {
      this.$store.dispatch(START_LOADING);
      InstituicaoEnsinoService.obterInstituicaoPorId(this.id)
        .then(({ data }) => {
          this.form = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    salvar() {
      if (!this.validarFormulario()) return;
      this.form.id ? this.editar() : this.criar();
    },
    criar() {
      this.$store.dispatch(START_LOADING);
      const formCustom = { ...this.form };
      formCustom.cnpj = formCustom.cnpj.replace(/\D/g, '');
      InstituicaoEnsinoService.criar(formCustom)
        .then((e) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), e.data.mensagem);
          this.irPara('instituicao-ensino');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar() {
      this.$store.dispatch(START_LOADING);
      const formCustom = { ...this.form };
      formCustom.cnpj = formCustom.cnpj.replace(/\D/g, '');
      InstituicaoEnsinoService.editar(formCustom)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Instituição editada!');
          this.irPara('instituicao-ensino');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      this.modais[key] = true;
      item
        ? (this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item)))
        : this.adicionaModel(key);
    },
    closeModal(key) {
      return (this.modais[`${key}`] = false);
    },
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    // FUNÇÕES AUXILIARES:
    adicionaModel(key) {
      switch (key) {
        case 'contato':
          this.modais[`${key}Dados`] = new Contato({});
          break;
        case 'endereco':
          this.modais[`${key}Dados`] = new Endereco({});
          break;
        default:
          break;
      }
    },
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
