<template>
  <modal
    :exibir="exibir"
    @fechar="fecharModal"
    :titulo="titulo"
    :campos="$refs"
    id="modalAdicionarContato"
  >
    <div class="flex-column flex-sm-row">
      <b-col cols="auto" class="col-sm-12">
        <input-text
          ref="formContato.nome"
          v-model="formContato.nome"
          :label="$t('PESSOAS.NOME')"
          placeholder="Nome do contato"
          required
        />
      </b-col>

      <b-col cols="auto" class="col-sm-12">
        <input-select-search
          ref="formContato.tipoContatoId"
          :options="listaTipoContato"
          v-model="formContato.tipoContatoId"
          :label="$t('PESSOAS.TIPO_CONTATO')"
          placeholder="Selecione ou digite"
          required
        />
      </b-col>

      <b-col class="col-sm-12">
        <input-text
          ref="descricao"
          v-model="formContato.descricao"
          :label="$t(`PESSOAS.${label.tipoContatoDescricao}`)"
          :contactType="formContato.tipoContatoDescricao"
          :placeholder="textoContato"
          :disabled="disabled.tipoContatoDescricao"
          required
        />
      </b-col>
    </div>
    <template v-slot:modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';
import InstituicaoEnsinoService from '@/common/services/instituicao-ensino/instituicao-ensino.js';

// Models:
import { FormContato } from '@/common/models';

// Components:
import Modal from '@/components/modal/Modal.vue';
import { InputSelectSearch, InputText } from '@/components/inputs';

export default {
  components: {
    Modal,
    InputSelectSearch,
    InputText,
  },
  props: {
    id: { type: String, default: null },
    exibir: { type: Boolean, default: false },
    item: { type: Object, default: null },
    form: { type: Object, default: Object },
  },
  data() {
    return {
      formContato: new FormContato({}),
      listaTipoContato: [],
      textoContato: 'Selecione um tipo de contato antes',
      selecaoTextoContato: [
        { id: 232, msg: 'Digite um endereço web' },
        { id: 246, msg: 'Digite um telefone residencial' },
        { id: 247, msg: 'Digite um telefone comercial' },
        { id: 248, msg: 'Digite um telefone celular' },
        { id: 249, msg: 'Digite um endereço de email' },
        { id: 250, msg: 'Digite um endereço de fax' },
      ],
      listaTipoEntregaCorrespondencia: [],
      label: {
        tipoContatoDescricao: 'DESCRICAO',
      },
      disabled: {
        tipoContatoDescricao: true,
      },
    };
  },
  mounted() {
    this.getTipoContato();
  },
  watch: {
    // para trocar o boolean recebido do back por texto com i18n:
    'formContato.tipoContatoId': [
      'trocaLabelTipoContatoDescricao',
      'atribuirDescricao',
    ],
  },
  computed: {
    titulo() {
      return !this.item
        ? this.$t('PESSOAS.CADASTRO_CONTATO')
        : this.$t('GERAL.EDITAR');
    },
  },
  methods: {
    habilitaCampo(campo) {
      this.disabled[campo] = false;
    },
    desabilitaCampo(campo) {
      this.disabled[campo] = true;
    },
    trocaLabelTipoContatoDescricao(tipoContato) {
      // limpa o campo descrição:
      this.formContato.descricao = '';
      // procura a descrição:
      this.selecaoTextoContato.forEach((x) => {
        if (x.id == tipoContato) {
          this.habilitaCampo('tipoContatoDescricao');
          this.textoContato = x.msg;
        }
      });
    },
    atribuirDescricao() {
      let tipoContatoDescricao = this.listaTipoContato.find(
        (item) => item.id === this.formContato.tipoContatoId
      );
      if (tipoContatoDescricao) {
        this.formContato.tipoContatoDescricao = tipoContatoDescricao.descricao;
      }
    },
    getTipoContato() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-contatos')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.listaTipoContato = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    salvar() {
      if (!this.validarFormulario()) return;
      this.atribuirDescricao();

      // se for uma instituição nova, só retorna para o form geral os contatos
      if (!this.id) {
        this.fecharModal();
        return this.form.contatos.push(this.formContato);
      }

      this.formContato.entidadeId = this.form.id;
      // OBS: Não existe editar contato, mas se um dia existir vou deixar preparado
      // se o item tem id ou qualquer informação então é ediçào
      if (this.item?.id) {
        this.editar(this.formContato);
      } else {
        // se não é criar um novo
        this.criar(this.formContato);
      }
      this.fecharModal();
    },
    criar(novoContato) {
      this.$store.dispatch(START_LOADING);
      InstituicaoEnsinoService.criarContato(novoContato)
        .then(({ data }) => {
          mensagem.sucesso('Contato criado!');
          this.form.contatos = [];
          this.form.contatos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar() {},
    // FUNÇÕES MODAL:
    fecharModal() {
      this.desabilitaCampo('tipoContatoDescricao');
      this.$emit('fechar');
    },
    // FUNÇÕES AUXILIARES:
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
  },
};
</script>
