<template>
  <div>
    <b-table
      :items="form.enderecos"
      :fields="campos"
      show-empty
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      stacked="md"
      responsive
      striped
      hover
    >
      <template v-slot:cell(acoes)="item">
        <div class="flex">
          <b-dropdown variant="outline-default">
            <b-dropdown-item @click="editar(item.item)">
              {{ $t('GERAL.EDITAR') }}
            </b-dropdown-item>
            <b-dropdown-item @click="confirmarExclusao(item)">
              {{ $t('GERAL.EXCLUIR') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
// Utils & Aux:
import mensagem from '@/common/utils/mensagem';
import { START_LOADING, STOP_LOADING } from '@/store/Store';

// Service:
import InstituicaoEnsinoService from '@/common/services/instituicao-ensino/instituicao-ensino.js';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  data() {
    return {
      id: this.$route.params.id,
      campos: [
        {
          key: 'cep',
          label: 'CEP',
        },
        {
          key: 'municipio.estado.nome',
          label: 'Estado',
        },
        {
          key: 'municipio.nome',
          label: 'Cidade',
        },
        {
          key: 'bairro',
          label: 'Bairro',
        },
        {
          key: 'logradouro',
          label: 'Logradouro',
        },
        {
          key: 'numero',
          label: 'Número',
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
    };
  },
  methods: {
    editar(endereco) {
      const enderecoFormatado = {...endereco,estadoId:endereco.municipio.estadoId}
      this.$emit('modal-endereco', 'endereco', enderecoFormatado);
    },
    excluir(id, endereco) {
      this.$store.dispatch(START_LOADING);
      InstituicaoEnsinoService.excluirEndereco(id)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Endereço removido!');
          this.form.enderecos.splice(endereco.index, 1);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async confirmarExclusao(enderecoRow) {
      const respostaExclusao = await mensagem.confirmacao(
        null,
        'Confirmar exclusão?'
      );
      if (respostaExclusao.isConfirmed) {
        if (!this.id) {
          this.form.enderecos.splice(enderecoRow.index, 1);
          return;
        }
        this.excluir(enderecoRow.item.id, enderecoRow);
      }
    },
  },
};
</script>
